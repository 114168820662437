@charset "utf-8";

.chat-bubble {
  padding-top: 5px;
  padding-bottom: 5px;
  height: auto !important;
  white-space: pre-wrap !important;
  word-wrap: break-word;
}

$primary: #59BFAF;

@import "~bulma/bulma.sass";
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';